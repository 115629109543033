<template>
  <div
    v-if="slideList.length"
    data-el="t3ce-rawlplug-sliderbanner"
    class="relative"
  >
    <Swiper
      v-bind="sliderOptions"
      class="w-full"
      @swiper="swiperInit"
      @slide-change="slideChange"
    >
      <SwiperSlide
        v-for="(slide, index) of slideList"
        :key="`t3ce-rawlplug-sliderbanner-slide-${index}`"
      >
        <picture
          v-if="slide.image?.src || slide.imageMobile?.src"
          data-el="t3ce-rawlplug-sliderbanner__picture"
        >
          <template v-if="slide.imageMobile?.src">
            <source
              :srcset="$img(slide.imageMobile?.src, {
                q: 85,
                width: 768,
              })"
              media="(max-width: 768px)"
            >
          </template>
          <UiImage
            v-if="slide.image?.src"
            :src="slide.image.src"
            :original-width="slide.image?.originalHeight"
            :original-height="slide.image?.originalWidth"
            class="w-full"
            width="1920"
          />
        </picture>

        <div class="container mb-10 md:mb-20">
          <div class="relative pt-3 md:w-1/2 md:border-b md:border-blue-100 md:pb-10 md:pt-16">
            <p
              v-if="slide?.subheader"
              class="mb-1.5 text-base font-bold text-primary md:mb-10 md:text-2xl"
            >
              {{ slide.subheader }}
            </p>

            <h2
              v-if="slide?.header"
              class="mb-11 text-xl md:mb-7 md:text-4xl"
            >
              {{ slide?.header }}
            </h2>

            <p
              v-if="slide?.description"
              class="text-primary"
            >
              {{ slide.description }}
            </p>

            <UiButton
              v-if="slide?.button?.link?.href && slide?.button?.text"
              :to="slide.button.link.href"
              variant="secondary"
              icon-right="external-link"
              slim
              :icon-dimensions="{
                height: 15,
                width: 15,
              }"
              class="mx-auto mt-5 w-full max-w-96 md:mx-0 md:mt-9 md:w-auto md:max-w-max"
            >
              {{ slide.button.text }}
            </UiButton>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>

    <div
      class="absolute z-10 w-full"
      data-el="t3ce-rawlplug-sliderbanner__nav"
    >
      <div class="container">
        <div
          class="flex items-center"
          data-el="t3ce-rawlplug-sliderbanner__nav-inside"
        >
          <button
            type="button"
            data-el="t3ce-rawlplug-sliderbanner__arrow"
            @click="slidePrev"
          >
            <UiIcon
              name="arrow-left"
              width="9"
              height="14"
              class="text-primary"
            />
          </button>

          <div>
            <!-- eslint-disable-next-line tailwindcss/no-custom-classname -->
            <div class="custom-swiper-pagination flex" />
          </div>

          <button
            type="button"
            data-el="t3ce-rawlplug-sliderbanner__arrow"
            @click="slideNext"
          >
            <UiIcon
              name="arrow"
              width="9"
              height="14"
              class="text-primary"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import type { SwiperOptions } from 'swiper/types/swiper-options'
import type SwiperClass from 'swiper'
import { Autoplay, EffectFade, Pagination } from 'swiper/modules'
import UiButton from '@ui/components/UiButton/UiButton.vue'
import UiIcon from '@ui/components/UiIcon/UiIcon.vue'
import UiImage from '@ui/components/UiImage/UiImage.vue'
import type { T3CeRawlplugSliderBannerProps } from './T3CeRawlplug_sliderbanner.types'

import 'swiper/css'
import 'swiper/css/effect-fade'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<T3CeRawlplugSliderBannerProps>()

const { $getImgProps } = useNuxtApp()
const $img = useImage()
const slider = ref<SwiperClass | undefined>(undefined)
const currentSlideNumber = ref<number>(1)

const slideList = computed(() => {
  if (!props.slides?.length) {
    return []
  }

  return props.slides.map((slide) => {
    return {
      ...slide,
      image: slide.image ? $getImgProps(slide.image) : null,
      imageMobile: slide.imageMobile ? $getImgProps(slide.imageMobile) : null,
    }
  }).filter(slide => slide?.image)
})

const sliderOptions = computed((): SwiperOptions => {
  return {
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    speed: 600,
    slidesPerView: 1,
    loop: true,
    modules: [Pagination, EffectFade, Autoplay],
    autoplay: {
      delay: 4000,
    },
    pagination: {
      clickable: true,
      bulletClass:
        'w-6 h-6 flex items-center justify-center before:rounded-full before:border before:border-primary before:bg-transparent before:block cursor-pointer before:w-3 before:h-3',
      bulletActiveClass: 'before:!bg-primary',
      el: '.custom-swiper-pagination',
    },
  }
})

function swiperInit(swiper: SwiperClass): void {
  slider.value = swiper
}

function slideChange(swiper: SwiperClass): void {
  currentSlideNumber.value = swiper.realIndex + 1
}

function slidePrev() {
  slider.value?.slidePrev()
}

function slideNext() {
  slider.value?.slideNext()
}

onBeforeUnmount(() => slider.value?.destroy())
</script>

<style lang="postcss">
[data-el='t3ce-rawlplug-sliderbanner__arrow'] {
  @apply w-10 h-10 flex items-center justify-center;
}

[data-el='t3ce-rawlplug-sliderbanner__nav'] {
  top: 320px;
}

[data-el='t3ce-rawlplug-sliderbanner__nav-inside'] {
  @apply mx-auto;
}

@media (max-width: theme('screens.md')) {
  [data-el='t3ce-rawlplug-sliderbanner__nav-inside'] {
    @apply justify-center;
  }

  [data-el='t3ce-rawlplug-sliderbanner__picture'],
  [data-el='t3ce-rawlplug-sliderbanner__picture'] > * {
    @apply h-80 object-cover mb-10;
  }
}

@media screen(md) {
  [data-el='t3ce-rawlplug-sliderbanner__picture'] {
    @apply absolute top-0 left-0 size-full mb-0;
  }

  [data-el='t3ce-rawlplug-sliderbanner__nav'] {
    @apply bottom-4 top-auto;
  }

  [data-el='t3ce-rawlplug-sliderbanner__nav-inside'] {
    @apply mx-0 -ml-4;
  }
}
</style>
